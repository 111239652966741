.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom horizontal scrollbar styles */
.table-container {
  overflow-x: auto;
}

/* Webkit scrollbar styles */
.table-container::-webkit-scrollbar {
  height: 8px; /* Adjust the height of the scrollbar */
}

.table-container::-webkit-scrollbar-track {
  background: transparent; /* Scrollbar track */
}

.table-container::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar thumb */
  border-radius: 4px; /* Rounded corners */
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover */
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
